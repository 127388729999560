export const environment = {
    appApiUrl: 'https://admin.amnestymoves.at/api/app/v1',
    appUrl: 'https://amnestymoves.at',
    entityId: 'c7708970-ca09-4024-86d1-3ccf6f1ed62d',
    environment: 'prod',
    client: 'aiat',
    faqSource : 'https://www.amnesty.at/json/faq/13121',
    firebaseAccount: 'aiat-prod',
    firebaseApiKey: 'AIzaSyCegpXLM0kTVmt00W1iFeh0XkIm1pzkXuQ',
    firebaseAppId: '1:319967573219:web:9d73ff8582756afba981d6',
    firebaseAuthDomain: 'amnestyaustria2go.firebaseapp.com',
    firebaseDatabaseURL: 'https://amnestyaustria2go.firebaseio.com',
    firebaseMessagingSenderId: '319967573219',
    firebaseProjectId: 'amnestyaustria2go',
    firebaseStorageBucket: 'amnestyaustria2go.appspot.com',
    googleMapsApiKey: 'AIzaSyCegpXLM0kTVmt00W1iFeh0XkIm1pzkXuQ',
    language: 'de',
    notificationsEnabled: true,
    preferredCountryCodes: ['AT', 'DE', 'CH'],
    privacyTermsUrl : 'https://www.amnesty.at/datenschutzhinweis#amnestymoves',
    production: true,
    provinceFilterEnabled: false,
    publicApiUrl: 'https://admin.amnestymoves.at/api/public/v1',
    releaseVersion: '56.4',
    sentryDsn: 'https://11d56fafe5bc4037bb67f6856dd0f356@sentry.webmozarts.com/11',
    sentryEnvironment: 'prod',
    sentryShowErrorDialog: false,
    sentryTracesSampleRate: 0.2,
    sentryRelease: '2go@56.4',
    validatedCountryCode: 'AT',
    defaultLongitude: 11.50464,
    defaultLatitude: 49.006131,
    defaultZoomLevel: 7,
    defaultMapRadius: 15,
    defaultListRadius: 100,
    phoneNumberMandatory: false,
    salutationMandatory: false,
    appProfileAddressLines: false,
    feedbackEmail: 'amnestymoves@amnesty.at',
    twitterIntegrationEnabled: false,
    twitterUsername: 'AmnestyAustria',
    facebookIntegrationEnabled: false,
    facebookAppId: '',
    appLinkTracking: {'amnesty.at': {'mobile': {'utm_medium': 'amnestymoves', 'utm_source': 'app-traffic-mobile'}, 'desktop': {'utm_medium': 'amnestymoves', 'utm_source': 'app-traffic-desktop'}}},
};
