// This file is generated from clients/[client]/app/menu.ts

export const menu = [
    {
        title: 'News',
        url: '/my-briefing',
        icon: 'checkmark-circle',
    },
    {
        title: 'Events & Kurse',
        url: '/ai-events',
        icon: 'calendar',
    },
    {
        title: 'Aktionen',
        url: '/activities',
        icon: 'rocket',
    },
    {
        title: 'Community',
        url: '/walls/17485893-b359-4832-ab9e-e192be8f7395',
        icon: 'people',
    },
    {
        title: 'Themen & Länder',
        url: '/topics',
        icon: 'earth',
    },
    {
        title: 'Amnesty Hub',
        url: 'https://www.amnesty.at/amnestyhub',
        icon: 'document-lock',
    },
    {
        title: 'FAQ',
        url: '/faq',
        icon: 'bulb',
    },
    {
        title: 'Kontakt & Feedback',
        // Needs to be kept in sync with feedback_email in config.yml
        url: 'mailto:amnestymoves@amnesty.at',
        target: '_self',
        icon: 'mail',
        hideOnMobile: true,
    },
];
